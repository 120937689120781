<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-card class="my-card m-0">
                    <b-table 
                    striped
                    small
                    id="myTables"
                    bordered
                    selectable
                    selected-variant="primary"
                    select-mode="single"
                    responsive="sm"
                    :items="balanceHistory"
                    :fields="field"
                    head-row-variant="secondary"
                    >
                    <template #cell(checkbox)="row">
                        <b-form-checkbox
                        v-model="row.detailsShowing"
                        plain
                        class="vs-checkbox-con"
                        @change="row.toggleDetails"
                        >
                        </b-form-checkbox>
                    </template>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
                    </b-table>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
export default {
    data() {
        return{
            balanceHistory:[],
            id: this.$route.query.filter,
            field: [
                {key: 'checkbox', label: '', thStyle: {width: '5px'}},
                {key: 'id', label: 'ID', thStyle: {width: '10px'}},
                {key: 'memo_subject_id', label: 'ID Темы заметки', thStyle: {width: '50px'}},
                {key: 'memo_type_id', label: 'ID Типа заметки', thStyle: {width: '40px'}},
                {key: 'status_id', label: 'Статус ID', thStyle: {width: '50px'}},
                {key: 'cause_id', label: 'ID причины', thStyle: {width: '50px'}},
                {key: 'phone', label: 'Номер телефона', thStyle: {width: '60px'}},
                {key: 'order_id', label: 'ID Заказа', thStyle: {width: '60px'}}, 
                {key: 'memo_subject.id', label: 'ID заметки', thStyle: {width: '70px'}}, 
                {key: 'memo_subject.name', label: 'Тема заметки', thStyle: {width: '100px'}}, 
                {key: 'memo_subject.is_active', label: 'Статус', thStyle: {width: '40px'}}, 
            ],
        }
    },
  mounted(){
      resizeable()
      this.$http
          .get(`drivers/${this.id}/memos`)
          .then(res => {
              this.balanceHistory = res.data
              this.$store.commit('pageData/setdataCount', this.balanceHistory.length)
          })
  },
}
</script>
